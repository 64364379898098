/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    headphones: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a5 5 0 00-5 5v1h1a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 01-1-1V8a6 6 0 1112 0v5a1 1 0 01-1 1h-1a1 1 0 01-1-1v-3a1 1 0 011-1h1V8a5 5 0 00-5-5"/>',
    },
});
